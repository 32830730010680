<template lang="pug">
	section#presidentes
		h3 Galeria de Presidentes
		ul
			Presidente(v-for="presidente in contents.content", :contents="presidente" :key="presidente.name.pt", @setContent="setContent")
		PresidenteContent(v-if="presidenteContent", :contents="presidenteContent", @setContent="setContent")
</template>

<script>
import { components, props } from '@/mixins/components'

export default {
  name: 'section-presidentes',
  props,
  components,
  data () {
    return {
      presidenteContent: null
    }
  },
  methods: {
    setContent (content) {
      this.presidenteContent = content
    }
  }
}
</script>

<style lang="stylus" scoped src="./Presidentes.styl"></style>
